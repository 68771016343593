/*eslint-disable*/
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Tooltip,
  List,
  ListItem,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  Image,
  Collapse,
  IconButton,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { FaAngleRight, FaAngleDown, FaVoteYea } from "react-icons/fa";
import {
  LiveIcon,
  BonusIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  RouletteIcon,
  BrowseIcon,
  CasinoIcon,
  BetslipIcon,
  SportsIcon,
  ChatIcon,
} from "components/Icons/Icons";

import {
  GiTennisRacket,
  GiBasketballBall,
  GiCrossedSwords,
  GiPistolGun,
  GiBoxingGlove,
  GiBattleAxe,
  GiMegaphone,
  GiHorseHead,
  GiTreasureMap,
  GiCrown,
  GiMoneyStack,
  GiCarWheel,
  GiChessKnight,
  GiConsoleController,
  GiCheckeredFlag,
  GiLaurelsTrophy,
  GiCardAceHearts,
  GiGameConsole,
  GiPokerHand,
  GiArrowDunk,
  GiSpades,
  GiDiamonds,
  GiLockedChest,
  GiTrophyCup,
  GiRugbyConversion,
  GiHockey,
  GiBaseballBat,
  GiCricketBat,
} from "react-icons/gi";

import {
  FaTicketAlt,
  FaEye,
  FaHandshake,
  FaBlogger,
  FaComments,
  FaFutbol,
  FaUser,
  FaBalanceScale,
  FaHeadset,
  FaGlobe,
  FaHourglassStart,
  FaStar,
  FaHistory,
  FaFilm,
  FaDollarSign,
  FaHandHoldingUsd,
  FaFootballBall,
  FaDice,
} from "react-icons/fa";
import { MdLiveTv } from "react-icons/md";

import regalLogo from "assets/img/WagerStreet-logo.png";
import { Separator } from "components/Separator/Separator";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getSports, getCountries, getLeagues } from "../../actions/sports";

import MobileBetslip from "components/MobileBetslip";
import Betslip from "views/BetslipTemplate";

import { setBetslipCoords } from "actions/betslip";

import inactiveCasinoBg from "assets/img/sidebar/casino-poker-cards.jpg";
import activeCasinoBg from "assets/img/sidebar/casino-poker-cards-green.jpg";
import inactiveSportsBg from "assets/img/sidebar/sports-balls.jpg";
import activeSportsBg from "assets/img/sidebar/sports-balls-orange.jpg";

function SidebarResponsive({
  dispatch,
  sports,
  countries,
  leagues,
  wsConnected,
  logoText,
  leftMenu,
  secondary,
  betItems,
}) {
  const iconRef = useRef(null);

  useEffect(() => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      dispatch(setBetslipCoords({ top: rect.top, left: rect.left }));
    }
  }, [dispatch]);

  // to check for active links and opened collapses
  let location = useLocation();
  const navigate = useNavigate();
  // this is for the rest of the collapses
  const mainPanel = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName) ? "active" : "";
  };

  const sportsIconMapping = {
    342: <SportsIcon color="inherit" />,
    343: <GiTennisRacket color="inherit" />,
    348: <GiBasketballBall color="inherit" />,
    350: <FaFootballBall color="inherit" />,
    345: <GiRugbyConversion color="inherit" />,
    372: <GiRugbyConversion color="inherit" />,
    347: <GiHockey color="inherit" />,
    366: <GiBaseballBat color="inherit" />,
    386: <GiCricketBat color="inherit" />,
    427: <GiTennisRacket color="inherit" />,
  };

  const iconMapping = {
    CasinoIcon: <CasinoIcon color="inherit" />,
    SportsIcon: <SportsIcon color="inherit" />,
    LiveIcon: <LiveIcon color="inherit" />,
    BonusIcon: <BonusIcon color="inherit" />,
    PersonIcon: <PersonIcon color="inherit" />,
    DocumentIcon: <DocumentIcon color="inherit" />,
    RocketIcon: <RocketIcon color="inherit" />,
    // SoccerIcon: <SportsIcon color="inherit" />,
    TennisIcon: <GiTennisRacket color="inherit" />,
    BaseballIcon: <GiBaseballBat color="inherit" />,
    PoliticsIcon: <FaVoteYea color="inherit" />,
    BasketBallIcon: <GiBasketballBall color="inherit" />,
    CricketIcon: <GiCricketBat color="inherit" />,
    LeagueOfLegendsIcon: <GiCrossedSwords color="inherit" />,
    CSIcon: <GiPistolGun color="inherit" />,
    MMAIcon: <GiBoxingGlove color="inherit" />,
    DOTAIcon: <GiBattleAxe color="inherit" />,
    PromotionsIcon: <GiMegaphone color="inherit" />,
    RaffleIcon: <FaTicketAlt color="inherit" />,
    RaceIcon: <GiHorseHead color="inherit" />,
    PragmaticDropsIcon: <GiTreasureMap color="inherit" />,
    ViewAllIcon: <FaEye color="inherit" />,
    AffiliateIcon: <FaHandshake color="inherit" />,
    VipClubIcon: <GiCrown color="inherit" />,
    BlogIcon: <FaBlogger color="inherit" />,
    ForumIcon: <FaComments color="inherit" />,
    SponsorshipIcon: <GiMoneyStack color="inherit" />,
    GamblingIcon: <FaBalanceScale color="inherit" />,
    LiveSupportIcon: <FaHeadset color="inherit" />,
    LanguageIcon: <FaGlobe color="inherit" />,
    LiveEventsIcon: <MdLiveTv color="inherit" />,
    StartingSoonIcon: <FaHourglassStart color="inherit" />,
    MyBetsIcon: <GiPokerHand color="inherit" />,
    AllSportsIcon: <GiTrophyCup color="inherit" />,
    AllEsportsIcon: <GiConsoleController color="inherit" />,
    AllRacingIcon: <GiCheckeredFlag color="inherit" />,
    FavouritesIcon: <FaStar color="inherit" />,
    RecentIcon: <FaHistory color="inherit" />,
    ChallengesIcon: <GiLaurelsTrophy color="inherit" />,
    OriginalsIcon: <FaFilm color="inherit" />,
    ExclusivesIcon: <GiLockedChest color="inherit" />,
    SlotsIcon: <GiBattleAxe color="inherit" />,
    LiveCasinoIcon: <GiCardAceHearts color="inherit" />,
    GameShowsIcon: <GiGameConsole color="inherit" />,
    StakePokerIcon: <GiPokerHand color="inherit" />,
    BonusBuyIcon: <FaDollarSign color="inherit" />,
    EnhancedRTPIcon: <GiArrowDunk color="inherit" />,
    TableGamesIcon: <GiCardAceHearts color="inherit" />,
    BlackjackIcon: <GiSpades color="inherit" />,
    BaccaratIcon: <GiDiamonds color="inherit" />,
    RouletteIcon: <RouletteIcon color="inherit" />,
    StakeF1TeamIcon: <GiCarWheel color="inherit" />,
    UFCIcon: <GiBoxingGlove color="inherit" />,
    EvertonFootballClubIcon: <FaFutbol color="inherit" />,
    EsporteClubeJuventudeIcon: <FaFutbol color="inherit" />,
    EnyimbaFootballClubIcon: <FaFutbol color="inherit" />,
    DavisCupAndBillieJeanKingCupIcon: <GiTennisRacket color="inherit" />,
    TeamSecretIcon: <GiChessKnight color="inherit" />,
    KunAgueroIcon: <FaUser color="inherit" />,
    IsraelAdesanyaIcon: <GiBoxingGlove color="inherit" />,
    AlexPereiraIcon: <GiBoxingGlove color="inherit" />,
    AlexaGrassoIcon: <GiBoxingGlove color="inherit" />,
    AlexandrePantojaIcon: <GiBoxingGlove color="inherit" />,
    JailtonAlmeidaIcon: <GiBoxingGlove color="inherit" />,
    FittipaldiBrothersIcon: <GiCarWheel color="inherit" />,
    ProvidersIcon: <FaHandHoldingUsd color="inherit" />,
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBetslipOpen,
    onOpen: onBetslipOpen,
    onClose: onBetslipClose,
  } = useDisclosure(); // For the second drawer (Betslip)
  const activeBg = useColorModeValue("white", "gray.800");
  const inactiveBg = useColorModeValue("white", "gray.700");
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("gray.400", "gray.400");
  const bottomBarIconColor = useColorModeValue("gray.400", "gray.400");
  const bottomBarBg = useColorModeValue("gray.700", "gray.900");
  const buttonHoverbg = useColorModeValue("gray.300", "gray.600");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSportStatus, setisSportStatus] = useState(false);
  const [isCasinoStatus, setisCasinoStatus] = useState(false);

  const backgroundImages = {
    Casino: {
      active: activeCasinoBg,
      inactive: inactiveCasinoBg,
    },
    Sports: {
      active: activeSportsBg,
      inactive: inactiveSportsBg,
    },
    // Add more mappings as needed...
  };

  // WebSocket connection setup
  useEffect(() => {
    Object.values(leftMenu.leftMenu).map((menu) => {
      if (activeRoute(menu.path) === "active") {
        setStatus(menu.path);
      }
    });

    if (isSportStatus && wsConnected && !Object.keys(sports).length) {
      dispatch(getSports());
    }
  }, [isSportStatus, sports, wsConnected]);

  const btnRef = React.useRef();

  const setStatus = (path) => {
    setisSportStatus(false);
    setisCasinoStatus(false);

    if (path.startsWith("/sports")) {
      setisSportStatus(true);
    } else if (path.startsWith("/casino")) {
      setisCasinoStatus(true);
    }
  };

  const createLinks = (leftMenu) => {
    return Object.values(leftMenu).map((menu) => {
      const isActive = activeRoute(menu.path) === "active";
      const menuBg = backgroundImages[menu.menuName] || {};
      const bgImage = isActive ? menuBg.active : menuBg.inactive;
      const hoverBgImage = menuBg.active;

      return (
        <NavLink to={menu.path} key={menu.menuName}>
          <Button
            onClick={() => {
              setStatus(menu.path);
            }}
            justifyContent="flex-start"
            alignItems="center"
            bg={isActive ? activeBg : "transparent"}
            backgroundImage={bgImage}
            backgroundSize="cover"
            backgroundPosition="left"
            backgroundRepeat="no-repeat"
            py="12px" // Increased vertical padding for height
            px="20px" // Increased horizontal padding for width
            mb="16px" // Space between buttons
            mx="10px" // Horizontal space between buttons
            borderRadius="md" // Rounded corners for the button
            height="40px" // Increased height
            width="100%" // Ensure the button takes full width of the container
            _hover={{
              backgroundImage: hoverBgImage,
              backgroundSize: "cover",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            <Tooltip label={menu.menuName} placement="right" hasArrow>
              <Flex>
                <Text color={activeColor} my="auto" fontSize="md">
                  {" "}
                  {/* Increased font size */}
                  {document.documentElement.dir === "rtl"
                    ? menu.rtlName
                    : menu.menuName}
                </Text>
              </Flex>
            </Tooltip>
          </Button>
        </NavLink>
      );
    });
  };

  const createSubLinks = (leftMenu, countriesData, leaguesData, eventsData) => {
    // Helper function to format the strings of Navlinks
    const formatStringForURL = (str) => {
      if (typeof str === "string") {
        return str.replace(/\s+/g, ""); // Remove all white spaces
      }
      return ""; // Return an empty string if str is not a valid string
    };

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [countryDropdowns, setCountryDropdowns] = useState({});

    const toggleSubMenuDropdown = (menuName) => {
      setIsSubMenuOpen((prev) => ({
        ...prev,
        [menuName]: !prev[menuName],
      }));
    };

    const toggleCountryDropdown = (countryId) => {
      setCountryDropdowns((prev) => ({
        ...prev,
        [countryId]: !prev[countryId],
      }));
    };

    if (isSportStatus) {
      return (
        <Box
          bg={activeBg}
          w="100%"
          mb="10px"
          borderRadius="15px"
          _hover="none"
          _focus={{ boxShadow: "none" }}
          padding="10px 0px 10px 0px"
          mt={2}
        >
          <Box w="100%" mb="10px" borderRadius="15px" padding="2px">
            <Text color={inactiveColor} my="auto" fontSize="lg" pl="10px">
              Top Sports
            </Text>
          </Box>
          <Box pb={1}>
            <hr />
          </Box>
          {Object.values(leftMenu).map((menu) => {
            if (activeRoute(menu.path) === "active") {
              return Object.entries(sports).map(([sportId, sport]) => {
                // Filter countries based on sportId
                const filteredCountries =
                  Object.entries(countries).filter(
                    ([, country]) => country.sportId === sportId
                  ) || [];

                return (
                  <Box key={sportId} width="100%">
                    <Button
                      onClick={() => {
                        if (Object.keys(menu.subMenu).length > 0) {
                          toggleSubMenuDropdown(sport.name);
                        }
                        dispatch(getCountries(sportId));
                      }}
                      justifyContent="flex-start"
                      alignItems="center"
                      bg={activeBg}
                      w="100%"
                      borderRadius="15px"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{ boxShadow: "none" }}
                    >
                      <Flex alignItems="center">
                        <IconBox
                          bg={inactiveBg}
                          color="gray.400"
                          h="30px"
                          w="30px"
                          me="12px"
                        >
                          {/*{sportsIconMapping[sportId] || (*/}
                          {/*  <SoccerIcon color="inherit" />*/}
                          {/*)}*/}
                        </IconBox>
                        <Text
                          color={inactiveColor}
                          my="auto"
                          fontSize="sm"
                          fontWeight="bold"
                          isTruncated
                          maxWidth="120px"
                        >
                          {sport.name}
                        </Text>
                        {/* )} */}
                      </Flex>
                      {/* {!isCollapsed && ( */}
                      <Box ml={1}>
                        {isSubMenuOpen[sport.name] ? (
                          <FaAngleDown />
                        ) : (
                          <FaAngleRight />
                        )}
                      </Box>
                      {/* )} */}
                    </Button>
                    <Collapse in={isSubMenuOpen[sport.name]} animateOpacity>
                      <List spacing={2} mt={2}>
                        {/* Countries Dropdown */}
                        <ListItem borderRadius="md">
                          <List spacing={2} mt={2}>
                            {filteredCountries.map(([countryId, country]) => {
                              const filteredLeagues = [];
                              Object.entries(leagues).filter(
                                ([leagueId, league]) => {
                                  if (
                                    league.sportId.toString() === sportId &&
                                    league.countryId.toString() == countryId
                                  ) {
                                    filteredLeagues.push({
                                      ...league,
                                      id: leagueId,
                                    });
                                  }
                                }
                              );
                              return (
                                <ListItem key={countryId} borderRadius="md">
                                  <Button
                                    onClick={() => {
                                      toggleCountryDropdown(countryId);
                                      dispatch(getLeagues(sportId, countryId));
                                    }}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    bg="transparent"
                                    w="100%"
                                    _hover="none"
                                    _active={{
                                      bg: "inherit",
                                      transform: "none",
                                      borderColor: "transparent",
                                    }}
                                    _focus={{ boxShadow: "none" }}
                                    padding="5px"
                                    pl={8}
                                  >
                                    <Flex
                                      justifyContent="space-between"
                                      alignItems="center"
                                      w="100%"
                                    >
                                      <Text
                                        color={inactiveColor}
                                        my="auto"
                                        fontSize="sm"
                                      >
                                        {country.name}
                                      </Text>
                                      {countryDropdowns[countryId] ? (
                                        <FaAngleDown />
                                      ) : (
                                        <FaAngleRight />
                                      )}
                                    </Flex>
                                  </Button>
                                  <Collapse
                                    in={countryDropdowns[countryId]}
                                    animateOpacity
                                  >
                                    <List spacing={2} mt={2}>
                                      {filteredLeagues &&
                                        filteredLeagues.map((league) => {
                                          return (
                                            <ListItem
                                              key={league.id}
                                              borderRadius="md"
                                              pl={12}
                                            >
                                              <div
                                                onClick={() => {
                                                  // Directly using navigate on click
                                                  navigate(
                                                    `/sports/${formatStringForURL(
                                                      sport.name
                                                    )}/${formatStringForURL(
                                                      country.name
                                                    )}/${formatStringForURL(
                                                      league.name
                                                    )}`,
                                                    {
                                                      state: {
                                                        sportId: sportId.toString(),
                                                        leagueId: league.id.toString(),
                                                      },
                                                    }
                                                  );
                                                  onClose();
                                                }}
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                                  alignItems: "center",
                                                  width: "100%",
                                                  padding: "5px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <Flex
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  w="100%"
                                                >
                                                  <Text
                                                    color={
                                                      activeRoute(
                                                        league.name.replace(
                                                          /\s+/g,
                                                          ""
                                                        )
                                                      ) === "active"
                                                        ? activeColor
                                                        : inactiveColor
                                                    }
                                                    my="auto"
                                                    fontSize="13px"
                                                  >
                                                    {league.name}
                                                  </Text>
                                                </Flex>
                                              </div>
                                            </ListItem>
                                          );
                                        })}
                                    </List>
                                  </Collapse>
                                </ListItem>
                              );
                            })}
                          </List>
                        </ListItem>
                      </List>
                    </Collapse>
                  </Box>
                );
              });
            }

            return null; // Return null if there's no subMenu
          })}
        </Box>
      );
    }
    return null;
  };

  const CreateLeftMenus = (leftMenu) => {
    const Menulist = leftMenu.leftMenu;

    const toggleDropdown = (menuName) => {
      setIsMenuOpen((prev) => ({
        ...prev,
        [menuName]: !prev[menuName],
      }));
    };

    return (
      <Box
        bg={activeBg}
        w="100%"
        mb="10px"
        borderRadius="15px"
        _hover="none"
        _focus={{ boxShadow: "none" }}
        padding="2px"
        mt={2}
      >
        {Object.values(Menulist).map((menu, index) => {
          return (
            <Box key={index}>
              <Button
                onClick={() => {
                  if (Object.keys(menu.subMenu).length > 0) {
                    toggleDropdown(menu.menuName);
                  } else {
                    onClose();
                  }
                }}
                justifyContent="flex-start"
                alignItems="center"
                bg={activeBg}
                w="100%"
                borderRadius="15px"
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{ boxShadow: "none" }}
                padding="10px"
              >
                {Object.keys(menu.subMenu).length == 0 && (
                  <NavLink
                    to={
                      Object.keys(menu.subMenu).length === 0 ? menu.path : "#"
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Tooltip label={menu.menuName} placement="right" hasArrow>
                      <Flex>
                        {menu.icon && iconMapping[menu.icon] && (
                          <IconBox
                            bg={inactiveBg}
                            color="gray.400"
                            h="30px"
                            w="30px"
                            me="12px"
                          >
                            {iconMapping[menu.icon]}
                          </IconBox>
                        )}
                        <Text
                          color={inactiveColor}
                          my="auto"
                          fontSize="sm"
                          fontWeight="bold"
                          isTruncated
                          maxWidth="120px"
                        >
                          {menu.menuName}
                        </Text>
                        {/* )} */}
                      </Flex>
                    </Tooltip>
                  </NavLink>
                )}
                {Object.keys(menu.subMenu).length > 0 && (
                  <Tooltip label={menu.menuName} placement="right" hasArrow>
                    <Flex>
                      {menu.icon && iconMapping[menu.icon] && (
                        <IconBox
                          bg={inactiveBg}
                          color="gray.400"
                          h="30px"
                          w="30px"
                          me="12px"
                        >
                          {iconMapping[menu.icon]}
                        </IconBox>
                      )}
                      <Text
                        color={inactiveColor}
                        my="auto"
                        fontSize="sm"
                        fontWeight="bold"
                        isTruncated
                        maxWidth="120px"
                      >
                        {menu.menuName}
                      </Text>
                    </Flex>
                  </Tooltip>
                )}
                {Object.keys(menu.subMenu).length > 0 && (
                  <Box ml={1}>
                    {isMenuOpen[menu.menuName] ? (
                      <FaAngleDown />
                    ) : (
                      <FaAngleRight />
                    )}
                  </Box>
                )}
              </Button>
              <Collapse in={isMenuOpen[menu.menuName]} animateOpacity>
                <List spacing={2} mt={2}>
                  {Object.values(menu.subMenu).map((subMenuItem, index) => {
                    return (
                      <ListItem borderRadius="md" key={index}>
                        <NavLink
                          to={`/sports/${subMenuItem.menuName}`}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg="transparent"
                            w="100%"
                            _hover="none"
                            _active={{
                              bg: "inherit",
                              transform: "none",
                              borderColor: "transparent",
                            }}
                            _focus={{ boxShadow: "none" }}
                            padding="5px"
                            paddingBottom={5}
                          >
                            <Flex>
                              {subMenuItem.icon &&
                                iconMapping[subMenuItem.icon] && (
                                  <IconBox
                                    bg={inactiveBg}
                                    color="gray.400"
                                    h="30px"
                                    w="30px"
                                    me="12px"
                                  >
                                    {iconMapping[subMenuItem.icon]}
                                  </IconBox>
                                )}
                              <Text
                                color={inactiveColor}
                                my="auto"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                {subMenuItem.menuName}
                              </Text>
                            </Flex>
                          </Button>
                        </NavLink>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </Box>
          );
        })}
      </Box>
    );
  };

  const links = <>{createLinks(leftMenu.leftMenu)}</>;
  const subLinks = <>{createSubLinks(leftMenu.leftMenu)}</>;

  //  BRAND
  let hamburgerColor = useColorModeValue("gray.500", "gray.200");
  if (secondary === true) {
    hamburgerColor = "white";
  }
  var brand = (
    <Box pt={"35px"} mb="8px">
      <NavLink
        to="/home" // Direct navigation to "/home"
        style={{
          display: "flex",
          lineHeight: "100%",
          marginBottom: "30px",
          fontWeight: "bold",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: "11px",
          textDecoration: "none",
        }}
      >
        <Box>
          <Image src={regalLogo} alt="RegalLogo" w="180px" h="30px" me="10px" />
        </Box>
      </NavLink>
      <Separator></Separator>
    </Box>
  );

  // SIDEBAR
  return (
    <Flex
      bg={bottomBarBg}
      display={{ sm: "flex", lg: "none" }}
      ref={mainPanel}
      alignItems="center"
      position="fixed"
      bottom="0" // Stick to the bottom
      w="100%" // Full width for mobile view
      zIndex="1000" // Ensure it stays on top of other content
      justifyContent="space-around" // Space icons evenly
      padding="10px 0"
      pl={4}
      pr={4}
    >
      {/* Browse / Hamburger Icon */}
      <Box textAlign="center">
        <IconButton
          aria-label="Browse"
          icon={<BrowseIcon size="24px" color={bottomBarIconColor} />}
          onClick={onOpen}
          ref={btnRef}
          variant="ghost"
        />
        <Text color="white" fontSize="12px">
          Browse
        </Text>
      </Box>

      {/* Casino Icon */}
      <Box textAlign="center">
        <NavLink to="/casino">
          <IconButton
            aria-label="Casino"
            icon={<CasinoIcon size="24px" color={bottomBarIconColor} />}
            onClick={() => console.log("Casino")}
            variant="ghost"
          />
        </NavLink>
        <Text color="white" fontSize="12px">
          Casino
        </Text>
      </Box>

      {/* Betslip Icon */}
      <Box
        textAlign="center"
        ref={iconRef}
        className="betslip-icon"
        position="relative"
      >
        <IconButton
          aria-label="Betslip"
          icon={<BetslipIcon size="24px" color={bottomBarIconColor} />}
          onClick={onBetslipOpen}
          variant="ghost"
        />

        {/* Badge displaying number of bets */}
        {betItems.length > 0 && (
          <Box
            position="absolute"
            top="2px"
            right="1px"
            bg="blue.500"
            color="black"
            borderRadius="full"
            width="18px"
            height="18px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="12px"
          >
            {betItems.length}
          </Box>
        )}

        <Text color="white" fontSize="12px">
          Betslip
        </Text>
      </Box>

      {/* Sports Icon */}
      <Box textAlign="center">
        <NavLink to="/sports">
          <IconButton
            aria-label="Sports"
            icon={<SportsIcon size="24px" color={bottomBarIconColor} />}
            onClick={() => console.log("Sports")}
            variant="ghost"
          />
        </NavLink>
        <Text color="white" fontSize="12px">
          Sports
        </Text>
      </Box>

      {/* Chat Icon */}
      <Box textAlign="center">
        <NavLink to="#">
          <IconButton
            aria-label="Chat"
            icon={<ChatIcon size="24px" color={bottomBarIconColor} />}
            onClick={() => console.log("Chat")}
            variant="ghost"
          />
        </NavLink>
        <Text color="white" fontSize="12px">
          Chat
        </Text>
      </Box>

      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerContent
          w="100vw" // Full width of the viewport
          mt="60px" // Margin above the drawer
          mb="70px" // Margin below the drawer
          borderRadius="6px"
          maxW="100%"
        >
          <DrawerCloseButton
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody px="1rem">
            <Box maxW="100%" h="100vh">
              <Stack direction="row" spacing={4}>
                {links} {/* Render the links directly in Stack */}
              </Stack>
              {isSportStatus && (
                <Stack direction="column" spacing={4}>
                  <Box display="flex" flexWrap="wrap">
                    <CreateLeftMenus leftMenu={leftMenu.leftMenu4} />
                  </Box>
                </Stack>
              )}
              {isSportStatus && (
                <Stack direction="column" spacing={4}>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                  >
                    {subLinks}
                  </Box>
                </Stack>
              )}

              {isSportStatus && (
                <Stack direction="column" spacing={4}>
                  <Box display="flex" flexWrap="wrap">
                    <CreateLeftMenus leftMenu={leftMenu.leftMenu6} />
                  </Box>
                </Stack>
              )}

              {isSportStatus && (
                <Stack direction="column" spacing={4}>
                  <Box display="flex" flexWrap="wrap">
                    <CreateLeftMenus leftMenu={leftMenu.leftMenu7} />
                  </Box>
                </Stack>
              )}
              {isCasinoStatus && (
                <Stack direction="column" spacing={4}>
                  <Box display="flex" flexWrap="wrap">
                    <CreateLeftMenus leftMenu={leftMenu.leftMenu8} />
                  </Box>
                </Stack>
              )}

              {isCasinoStatus && (
                <Box
                  bg={activeBg}
                  w="100%"
                  mb="10px"
                  borderRadius="15px"
                  _hover="none"
                  _focus={{ boxShadow: "none" }}
                  padding="10px 0px 0px 0px"
                >
                  <Box w="100%" mb="10px" borderRadius="15px" padding="10px">
                    <Text
                      color={inactiveColor}
                      my="auto"
                      fontSize="lg"
                      pl="10px"
                    >
                      Games
                    </Text>
                  </Box>

                  <Box pb={5}>
                    <hr />
                  </Box>

                  <Stack direction="column" spacing={4}>
                    <Box display="flex" flexWrap="wrap">
                      <CreateLeftMenus leftMenu={leftMenu.leftMenu9} />
                    </Box>
                  </Stack>
                </Box>
              )}

              {isCasinoStatus && (
                <Stack direction="column" spacing={4}>
                  <Box display="flex" flexWrap="wrap">
                    <CreateLeftMenus leftMenu={leftMenu.leftMenu10} />
                  </Box>
                </Stack>
              )}
              <Stack direction="column" spacing={4}>
                <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                  <CreateLeftMenus leftMenu={leftMenu.leftMenu2} />
                </Box>
              </Stack>

              <Stack direction="column" spacing={4}>
                <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                  <CreateLeftMenus leftMenu={leftMenu.leftMenu3} />
                </Box>
              </Stack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Second Drawer (Betslip) */}
      <Drawer isOpen={isBetslipOpen} onClose={onBetslipClose}>
        <DrawerContent w="100vw" maxW="100%" borderRadius="6px" mb="70px">
          <DrawerCloseButton />
          <DrawerBody px="0rem" py="0rem">
            <Box>
              <Betslip />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

const s2p = (state) => ({
  sports: state.sports.sports,
  countries: state.sports.countries,
  leagues: state.sports.leagues,
  wsConnected: state.websocket?.connected || false,
  betItems: state.betSlip.betItems,
});

export default connect(s2p)(SidebarResponsive);
