import React from "react";
import {
    Flex,
} from "@chakra-ui/react";

export default function Casino() {
    return (
        <Flex flexDirection="column" pt={{ base: "120px", md: "65px" }}>
            <div style={{ height: '100vh', overflow: 'hidden' }}>
                <iframe
                    title="WagerStreet Casino"
                    src="https://wagerstreet.net/en?t=casino&gid=137000290&gi=BETC&p=guest&gt=home&gn=32+cards&gpid=xtremeslots"
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                />
            </div>
        </Flex>
    );
}
