import React from "react";
import {
  Box,
  Image,
  Text,
  Flex,
  SimpleGrid,
  Icon,
  Grid,
  GridItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { NavLink } from 'react-router-dom';
import {
  FaBitcoin,
  FaSearch,
  FaNewspaper,
  FaBasketballBall,
  FaDice,
  FaBox,
  FaAngleRight
} from "react-icons/fa";
import BlogImage from "assets/img/blog/blog-image.png";
import CryptoImage from "assets/img/blog/crypto-image.webp";
import GuidesImage from "assets/img/blog/guides-image.webp";
import StakeNewsImage from "assets/img/blog/stake-news-image.webp";
import SportsImage from "assets/img/blog/sports-image.webp";
import CasinoImage from "assets/img/blog/casino-image.webp";
import OtherImage from "assets/img/blog/other-image.webp";
import blogFeaturedImage from "assets/img/blog/blog-featured-image.webp";
import ufcPicksBlogImage from "assets/img/blog/ufc-picks-blog image.webp";
import mlbPredictionBlogImage from "assets/img/blog/2024-mlb-blog-image.webp";
import newGamesOneBlogImage from "assets/img/blog/new-games-1-blog-image.webp";
import whatIsDoubleBlogImage from "assets/img/blog/what-is-double-blog-image.webp";
import commonSportsBlogImage from "assets/img/blog/common-sports-blog-image.webp";
import newGamesTwoBlogImage from "assets/img/blog/new-games-2-blog-image.webp";


const Blog = () => {

  const bg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  const textColor1 = useColorModeValue("gray.800", "gray.500");
  const iconColor = useColorModeValue("gray.600", "gray.300");
  const tabBg = useColorModeValue("gray.100", "gray.700");
  // const inputBg = useColorModeValue("gray.200", "gray.700");

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "65px" }}
      px={{ base: 4, md: 8 }}
      bg={bg}
      color={textColor}
      minH="100vh"
    >
      <Box padding="4" bg={bg}>
        {/* Blog Header */}
        <Text fontSize="2xl" fontWeight="bold" color={textColor} mb="6">
          Blog
        </Text>

        {/* Main Featured Blog Section */}
        {/* <Flex direction={{ base: "column", md: "row" }} mb="10"> */}
        <NavLink to="/blog/what-is-3-bet-poker-strategy">
          <Image
            src={BlogImage}
            alt="Featured Blog"
            borderRadius="md"
            maxWidth={{ base: "100%", md: "100%" }}
            transition="all 0.2s ease-in-out"
            _hover={{ transform: "translateY(-10px)" }}
          />

          <Box
          >
            <Text fontSize="3xl" fontWeight="bold" mt="4">
              What is the 3-Bet Poker Strategy?
            </Text>
            <Text fontSize="xl" mb={4} color={textColor1}>
              A 3-bet in poker is the third wager made in a betting round. read our tips & all there is to know about this elevated strategy at Wager Street Casino!
            </Text>
          </Box>
        </NavLink>


        {/* Blog Categories */}
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="4" mb={6}>
          {/* Crypto Category */}
          <NavLink to="/blog/crypto">
            <Flex
              alignItems="center"
              backgroundColor={tabBg}
              borderRadius="md"
            >
              <Image
                src={CryptoImage}
                alt="2024 MLB Predictions & World Series Picks"
                borderRadius="md"
                width={20}
                height={20}
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" pl={4} fontSize={"xl"}>
                Crypto
              </Text>
              <Box ml="auto">
                <Icon as={FaAngleRight} w={8} h={8} color={textColor1} />
              </Box>
            </Flex>
          </NavLink>



          {/* How to Guides Category */}
          <NavLink to="/blog/howtoguides">
            <Flex
              alignItems="center"
              backgroundColor={tabBg}
              borderRadius="md"
              cursor="pointer"
            >
              <Image
                src={GuidesImage}
                alt="2024 MLB Predictions & World Series Picks"
                borderRadius="md"

                width={20}
                height={20}
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" pl={4} fontSize={"xl"}>
                How to Guides
              </Text>
              <Box ml="auto">
                <Icon as={FaAngleRight} w={8} h={8} color={textColor1} />
              </Box>
            </Flex>
          </NavLink>

          {/* Wager Street News Category */}
          <NavLink to="/blog/stakenews">
            <Flex
              alignItems="center"
              backgroundColor={tabBg}
              borderRadius="md"
              cursor="pointer"
            >
              <Image
                src={StakeNewsImage}
                alt="2024 MLB Predictions & World Series Picks"
                borderRadius="md"

                width={20}
                height={20}
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" pl={4} fontSize={"xl"}>
                Wager Street News
              </Text>
              <Box ml="auto">
                <Icon as={FaAngleRight} w={8} h={8} color={textColor1} />
              </Box>
            </Flex>
          </NavLink>

          {/* Sport Category */}
          <NavLink to="/blog/sports">
            <Flex
              alignItems="center"
              backgroundColor={tabBg}
              borderRadius="md"
              cursor="pointer"
            >
              <Image
                src={SportsImage}
                alt="2024 MLB Predictions & World Series Picks"
                borderRadius="md"

                width={20}
                height={20}
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" pl={4} fontSize={"xl"}>
                Sports
              </Text>
              <Box ml="auto">
                <Icon as={FaAngleRight} w={8} h={8} color={textColor1} />
              </Box>
            </Flex>
          </NavLink>

          {/* Casino Category */}
          <NavLink to="/blog/casino">
            <Flex
              alignItems="center"
              backgroundColor={tabBg}
              borderRadius="md"
              cursor="pointer"
            >
              <Image
                src={CasinoImage}
                alt="2024 MLB Predictions & World Series Picks"
                borderRadius="md"

                width={20}
                height={20}
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" pl={4} fontSize={"xl"}>
                Casino
              </Text>
              <Box ml="auto">
                <Icon as={FaAngleRight} w={8} h={8} color={textColor1} />
              </Box>
            </Flex>
          </NavLink>

          {/* Other Category */}
          <NavLink to="/blog/other">
            <Flex
              alignItems="center"
              backgroundColor={tabBg}
              borderRadius="md"
              cursor="pointer"
            >
              <Image
                src={OtherImage}
                alt="2024 MLB Predictions & World Series Picks"
                borderRadius="md"

                width={20}
                height={20}
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" pl={4} fontSize={"xl"}>
                Other
              </Text>
              <Box ml="auto">
                <Icon as={FaAngleRight} w={8} h={8} color={textColor1} />
              </Box>
            </Flex>
          </NavLink>
        </SimpleGrid>

        {/* Blog Posts Section */}
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
          <NavLink to="/blog/ufc-picks">
            <Box backgroundColor={bg} borderRadius="md" p="4">
              <Image
                src={ufcPicksBlogImage}
                alt="UFC Picks & Expert UFC Predictions"
                borderRadius="md"
                mb="4"
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
                UFC Picks & Expert UFC Predictions
              </Text>
              <Text color={textColor}>
                Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                odds on all UFC fights and place your bets.
              </Text>
            </Box>
          </NavLink>
          <NavLink to="/blog/mlb_predictions_and_world_series_picks">
            <Box backgroundColor={bg} borderRadius="md" p="4">
              <Image
                src={mlbPredictionBlogImage}
                alt="2024 MLB Predictions & World Series Picks"
                borderRadius="md"
                mb="4"
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
                2024 MLB Predictions & World Series Picks
              </Text>
              <Text color={textColor}>
                MLB predictions for the 2024 season are here, with baseball picks to
                win from our expert tipsters. See which team is predicted to win the
                World Series and make your selections on our Sportsbook!
              </Text>
            </Box>
          </NavLink>

          <NavLink to="/blog/new_games_on_stake">
            <Box backgroundColor={bg} borderRadius="md" p="4">
              <Image
                src={newGamesOneBlogImage}
                alt="New Games on Wager Street: August 30th 2024 Casino Releases & Sports Promos"
                borderRadius="md"
                mb="4"
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
                New Games on Wager Street: August 30th 2024 Casino Releases & Sports Promos
              </Text>
              <Text color={textColor}>
                We&apos;re wrapping up the week by looking at top sporting events,
                22 new-release games &amp; fresh bet bonuses and promos! Find out
                how to join the latest casino competitions today!
              </Text>
            </Box>
          </NavLink>
          <NavLink to="/blog/double_chance_betting">
            <Box backgroundColor={bg} borderRadius="md" p="4">
              <Image
                src={whatIsDoubleBlogImage}
                alt="Another Blog Post Title"
                borderRadius="md"
                mb="4"
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
                What is Double Chance Betting?
              </Text>
              <Text color={textColor}>
                Learn all about double chance betting including the benefits, payout
                methods, strategies and more! Become a pro at betting on soccer &
                more at Wager Street Sportsbook.
              </Text>
            </Box>
          </NavLink>
          <NavLink to="/blog/common_sports_betting">
            <Box backgroundColor={bg} borderRadius="md" p="4">
              <Image
                src={commonSportsBlogImage}
                alt="Another Blog Post Title"
                borderRadius="md"
                mb="4"
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
                Common Sports Betting Slang Terms
              </Text>
              <Text color={textColor}>
                If you&apos;re new to sports betting, check out our complete
                glossary of key terms & common slang! Learn odds from lines, futures
                & more!
              </Text>
            </Box>
          </NavLink>
          <NavLink to="/blog/new_games_on_casino">
            <Box backgroundColor={bg} borderRadius="md" p="4">
              <Image
                src={newGamesTwoBlogImage}
                alt="Another Blog Post Title"
                borderRadius="md"
                mb="4"
                transition="all 0.2s ease-in-out"
                _hover={{ transform: "translateY(-10px)" }}
              />
              <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
                New Games on Wager Street: August 23rd 2024 Casino Releases & Sports Promos
              </Text>
              <Text color={textColor}>
                With a drop of 11 new games and plenty of casino & sports betting
                promos, there&apos;s plenty of reasons to be excited this week! Find
                out how to get involved in our weekly wrap up!
              </Text>
            </Box>
          </NavLink>
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default Blog;
