import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Box,
  Flex,
  Link,
  useColorModeValue,
  Image,
  Text,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import AdminNavbarLinks from "./AdminNavbarLinks";
import regalLogo from "assets/img/WagerStreet-logo.png";

const AdminNavbar = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [scrolled, setScrolled] = useState(false);
  const {
    variant,
    brandText,
    fixed,
    secondary,
    openLogin,
    openRegister,
    isCollapsed,
    logoText, // Added to destructure
    maxW,
    isBetSlipStatus,
  } = props;

  // Here are all the props that may change depending on navbar's type or state.
  let mainText = useColorModeValue("gray.700", "gray.200");
  let secondaryText = useColorModeValue("gray.400", "gray.200");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(21px)";
  let navbarShadow = "none";
  let navbarBg = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";

  if (fixed) {
    if (scrolled) {
      navbarPosition = "fixed";
      navbarShadow = useColorModeValue(
          "0px 7px 23px rgba(0, 0, 0, 0.05)",
          "none"
      );
      navbarBg = useColorModeValue(
          "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
          "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );
      navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
      navbarFilter = useColorModeValue(
          "none",
          "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
      );
    }
  }

  if (secondary) {
    navbarBackdrop = "none";
    navbarPosition = "fixed";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingX = "30px";
  }

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);
    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);

  // return (
  //   <Flex
  //     maxW={maxW}
  //     position="fixed" // Change navbar position to fixed
  //     top="0" // Ensure the navbar is fixed to the top
  //     boxShadow={navbarShadow}
  //     bg="gray.700"
  //     borderColor={navbarBorder}
  //     filter={navbarFilter}
  //     backdropFilter={navbarBackdrop}
  //     borderWidth="1.5px"
  //     borderStyle="solid"
  //     transition="box-shadow 0.25s linear, background-color 0.25s linear, filter 0.25s linear, border 0s linear"
  //     display="flex"
  //     minH="75px"
  //     justifyContent="space-between"
  //     alignItems="center"
  //     lineHeight="25.6px"
  //     mx="auto"
  //     mt={secondaryMargin}
  //     pb="8px"
  //     left={{
  //       sm: "",
  //       lg: "110px",
  //       xl: isCollapsed ? "110px" : "250px",
  //     }}
  //     // right={isCollapsed ? "110px" : "350px"}
  //
  //     px={{
  //       sm: paddingX,
  //       md: "30px",
  //     }}
  //     ps={{
  //       xl: "12px",
  //     }}
  //     pt="8px"
  //     w={{
  //       sm: "calc(100vw)",
  //       lg: isBetSlipStatus ? "calc(100vw - 38% )" : "calc(100vw - 10%)",
  //       xl: isCollapsed
  //         ? isBetSlipStatus
  //           ? "calc(100% - 10% - 20px - 340px)" // When isCollapsed is true and isBetSlipStatus is true
  //           : "calc(100vw - 100px - 20px)" // When isCollapsed is true and isBetSlipStatus is false
  //         : isBetSlipStatus
  //         ? "calc(100vw - 40%)" // When isCollapsed is false and isBetSlipStatus is true
  //         : "calc(100vw - 100px - 160px)", // When isCollapsed is false and isBetSlipStatus is false
  //
  //       xxl: isCollapsed
  //         ? isBetSlipStatus
  //           ? "calc(100% - 30.5%)" // When isCollapsed is true and isBetSlipStatus is true
  //           : "calc(100vw - 100px - 20px)" // When isCollapsed is true and isBetSlipStatus is false
  //         : isBetSlipStatus
  //         ? "calc(100vw - 38.5%)" // When isCollapsed is false and isBetSlipStatus is true
  //         : "calc(100vw - 100px - 160px)", // When isCollapsed is false and isBetSlipStatus is false
  //
  //       xxxl: isCollapsed
  //         ? isBetSlipStatus
  //           ? "calc(100% - 10% - 20px - 340px)" // When isCollapsed is true and isBetSlipStatus is true
  //           : "calc(100vw - 100px - 20px)" // When isCollapsed is true and isBetSlipStatus is false
  //         : isBetSlipStatus
  //         ? "calc(100vw - 37%)" // When isCollapsed is false and isBetSlipStatus is true
  //         : "calc(100vw - 100px - 160px)", // When isCollapsed is false and isBetSlipStatus is false
  //
  //       xxxxl: isCollapsed
  //         ? isBetSlipStatus
  //           ? "calc(100% - 10% - 20px - 340px)" // When isCollapsed is true and isBetSlipStatus is true
  //           : "calc(100vw - 100px - 20px)" // When isCollapsed is true and isBetSlipStatus is false
  //         : isBetSlipStatus
  //         ? "calc(100vw - 35%)" // When isCollapsed is false and isBetSlipStatus is true
  //         : "calc(100vw - 100px - 160px)", // When isCollapsed is false and isBetSlipStatus is false
  //     }}
  //   >
  {/*<Flex w="100%" flexDirection="row" alignItems="center">*/}
  {/*  <Box>*/}
  {/*    <NavLink*/}
  {/*      to="/home" // Direct navigation to "/home"*/}
  {/*      style={{*/}
  {/*        display: "flex",*/}
  {/*        lineHeight: "100%",*/}
  {/*        marginBottom: "20px",*/}
  {/*        fontWeight: "bold",*/}
  {/*        justifyContent: isCollapsed ? "flex-start" : "center",*/}
  {/*        alignItems: "center",*/}
  {/*        fontSize: "11px",*/}
  {/*        textDecoration: "none",*/}
  {/*      }}*/}
  {/*    >*/}
  {/*      <Box>*/}
  {/*        <Image*/}
  {/*          src={regalLogo}*/}
  {/*          alt="RegalLogo"*/}
  {/*          width="150px"*/}
  {/*          height="30px"*/}
  {/*        />*/}
  {/*      </Box>*/}
  {/*    </NavLink>*/}
  {/*  </Box>*/}

  {/*  /!* <Box ms="auto" w={{ sm: "100%", md: "unset" }}>*/}
  {/*    <AdminNavbarLinks*/}
  {/*      onOpen={onOpen}*/}
  {/*      logoText={logoText}*/}
  {/*      secondary={secondary}*/}
  {/*      fixed={fixed}*/}
  {/*    />*/}
  {/*  </Box> *!/*/}
  {/*  <Box*/}
  {/*    ms="auto"*/}
  {/*    w={{ sm: "100%", md: "unset" }}*/}
  {/*    display="flex"*/}
  {/*    alignItems="center"*/}
  {/*    justifyContent="flex-end"*/}
  {/*    gap={{ base: "10px", md: "20px" }}*/}
  {/*  >*/}
  {/*    /!* Sign In modal link *!/*/}
  {/*    <NavLink*/}
  {/*      onClick={openLogin} // Directly call the onOpen function*/}
  {/*      style={{*/}
  {/*        textDecoration: "none",*/}
  {/*        color: "inherit",*/}
  {/*        fontSize: "14px",*/}
  {/*        fontWeight: "bold",*/}
  {/*      }}*/}
  {/*    >*/}
  {/*      Sign In*/}
  {/*    </NavLink>*/}

  {/*    /!* Register modal button *!/*/}
  {/*    <Button*/}
  {/*      onClick={openRegister} // Trigger modal open on click*/}
  {/*      bg="blue.500"*/}
  {/*      color="white"*/}
  {/*      _hover={{ bg: "blue.600" }}*/}
  {/*      fontSize="14px"*/}
  {/*      fontWeight="bold"*/}
  {/*    >*/}
  {/*      Register*/}
  {/*    </Button>*/}
  {/*  </Box>*/}
  {/*</Flex>*/}
  // </Flex>
  // );
};

AdminNavbar.propTypes = {
  brandText: PropTypes.string.isRequired,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
  isCollapsed: PropTypes.bool,
  logoText: PropTypes.string, // Added here
};

const s2p = (state) => ({
  isBetSlipStatus: state.betSlip.isBetSlipStatus, // map the state to props
});

// Connect Redux store to the Dashboard component
export default connect(s2p)(AdminNavbar);
