import React from "react";
import {
    Box,
    Image,
    Text,
    Flex,
    SimpleGrid,
    useColorModeValue,
} from "@chakra-ui/react";

import logoImage from "assets/img/blog/logo-image.png";
import OtherImage1 from "assets/img/blog/Other/image-1.png";
import { NavLink } from "react-router-dom";


const Other = () => {
    const tabBg = useColorModeValue("gray.100", "gray.700");
    const bg = useColorModeValue("white", "gray.800");
    const headerbg = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");

    const header = useColorModeValue("gray.800", "white");
    // const inputBg = useColorModeValue("gray.200", "gray.700");

    return (
        <Flex
            flexDirection="column"
            pt={{ base: "120px", md: "65px" }}
            px={{ base: 4, md: 8 }}
            bg={bg}
            color={textColor}
            minH="100vh"
        >
            <Box padding="4" bg={bg}>
                {/* Crypto Header */}
                <Flex justify="space-between" alignItems="center" bg={tabBg}>
                    <Text fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} fontWeight={"medium"} color={textColor} pl={4}>
                        Other
                    </Text>
                    <Image
                        src={logoImage}
                        alt="Featured Blog"
                        borderRadius="md"
                        maxWidth={{ base: "100%", sm: "50%", md: "50%" }}
                        transition="all 0.2s ease-in-out"
                        _hover={{ transform: "translateY(-10px)" }}
                    />



                </Flex>

                {/* Blog Posts Section */}
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
                    <NavLink to = "/blog/other_sub_blog">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={OtherImage1}
                                alt="UFC Picks & Expert UFC Predictions"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">UFC®, the World’s Premier Mixed Martial Arts Organization, Today Announced a New Partnership with Wager Street, the World’s Largest Cryptocurrency Casino and Sportsbook
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Las Vegas – UFC ®, the world’s premier mixed martial arts organization, today announced a new partnership with Wager Street, the world’s largest cryptocurrency casino and sportsbook
                                Under the terms of the agreement, WagerStreet.net will become UFC’s first-ever “Official Betting Partner” in Latin America (excluding Brazil) and Asia, creating a new sponsorship category for the organization.
                            </Text>
                        </Box>
                    </NavLink>
                </SimpleGrid>
            </Box>
        </Flex>
    );
};

export default Other;
