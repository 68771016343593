// Chakra imports
import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  Text,
  Button,
  Input,
  Tabs,
  TabList,
  Tab,
  Image,
  useColorModeValue,
  Badge,
  TabPanels,
  TabPanel,
  Table,
  Stack,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Link,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React from "react";
import Authors from "./components/Authors";
import Projects from "./components/Projects";
import {
  FaSearch,
  FaStopwatch,
  FaMoneyBillWave,
  FaHeart,
} from "react-icons/fa";
import { MdOutlineSportsVolleyball } from "react-icons/md";
import { tablesTableData, dashboardTableData } from "variables/general";
import politicsImage from "assets/img/politics/politics-image.jpg";
import soccerImage from "assets/img/sports/Soccer.png";
import tennisImage from "assets/img/sports/Tennis.png";
import racingImage from "assets/img/sports/Racing.png";
import politicsAndEnterImage from "assets/img/sports/Politics-Entertainment.png";
import cricketImage from "assets/img/sports/Cricket.png";
import baseballImage from "assets/img/sports/Baseball.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const images = [
  soccerImage,
  tennisImage,
  racingImage,
  politicsAndEnterImage,
  cricketImage,
  baseballImage,
];

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  const iconColor = useColorModeValue("black", "white");

  return (
    <ChevronRightIcon
      className={className}
      style={{ ...style, color: iconColor, fontSize: "2rem" }}
      onClick={onClick}
    />
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  const iconColor = useColorModeValue("black", "white");

  return (
    <ChevronLeftIcon
      className={className}
      style={{ ...style, color: iconColor, fontSize: "2rem", left: "-35px" }}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: false,
  infinite: false, // Set infinite to false so it doesn't loop
  speed: 500,
  slidesToShow: 2, // Adjust as needed
  slidesToScroll: 2,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const carouselSettings = {
  infinite: true,
  speed: 300,
  slidesToShow: 6, // Number of slides visible at once
  slidesToScroll: 2, // Number of slides to scroll at once
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  responsive: [
    {
      breakpoint: 768, // For smaller devices
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 480, // For mobile devices
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

const sportSliders = [
  {
      image: soccerImage,
      alt: 'Soccer',
      title: 'UEFA Champions League',
      description: 'Italy won the League, You Win too!',
  },
  {
      image: tennisImage,
      alt: 'Tennis',
      title: 'Serena Williams',
      description: 'Wins the title!',
  },
  {
      image: racingImage,
      alt: 'Racing',
      title: 'Laurel River',
      description: 'The Champion made it again!',
  },
  {
      image: politicsAndEnterImage,
      alt: 'Politics and Entertainment',
      title: 'U.S.  Presidential Election',
      description: '220+ Electoral Votes,You Win!',
  },
  {
      image: cricketImage,
      alt: 'Cricket',
      title: 'India in the Lead',
      description: 'Why India is always the best in cricket?',
  },
  {
      image: baseballImage,
      alt: 'Baseball',
      title: 'MSB Baseball League',
      description: 'Cleveland Guardians won 3-1!',
  },
];

function Sports() {
  return (
    <Flex direction="column" pt={{ base: "120px", md: "65px" }}>
      {/* Promo and politics section */}
      <Slider {...settings}>
        {sportSliders.map(({
          image,
          alt,
          description,
          title,
        }, index) => (
          <SimpleGrid key={index} columns={{ base: 1, md: 2,lg:3}}>
            <Box m={1}
              bg={useColorModeValue("white", "gray.700")}
              p="16px"
              borderRadius="12px"
              h="100%"
              boxShadow="lg"
            >
              <Flex justify="space-between" align="center" h="100%" gap={"10px"}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  h="100%"
                >
                  <Box>
                    <Badge
                      mb={12}
                      lineHeight="1.5"
                      p="0 0.25rem"
                      borderRadius="3px"
                      color="#071824"
                      backgroundColor="white"
                    >
                      {alt}
                    </Badge>
                    <Text fontSize="x" fontWeight="bold">
                      {title}
                    </Text>
                    <Text fontSize="0.8em">
                      {description}
                      <br /><Link>Read More</Link>
                    </Text>
                  </Box>
                  <Button mt="12px" alignSelf="start">
                    View Market
                  </Button>{" "}
                  {/* alignSelf="start" can be omitted, button will stay at the bottom */}
                </Box>
                <Image
                  src={image}
                  alt="Daily Races"
                  boxSize={{ base: "120px", md: "150px", lg: "200px" }}
                  minW="10px"
                  maxW="100%"
                  objectFit="cover"
                  transition="all 0.2s ease-in-out" _hover={{ transform: "translateY(-10px)" }}
                />
              </Flex>
            </Box>
          </SimpleGrid>
        ))}
      </Slider>
      {/* Search Bar */}
      <Box mb="24px">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <FaSearch color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search your event"
            size="md"
            borderRadius="12px"
          />
        </InputGroup>
      </Box>

      {/* Sports Tabs */}
      <Box>
        <Tabs
          boxShadow="lg"
          variant="solid-rounded"
          colorScheme="teal"
          size={{ base: "sm", md: "md" }} // Responsive tab size
          p={2}
          isLazy // Lazy loading for performance
        >
          <TabList
            mb={6}
            pb={2}
            overflowX="auto" // Enable horizontal scrolling on smaller screens
            whiteSpace="nowrap" // Prevent tabs from wrapping
          >
            <Tab minWidth="100px">Lobby</Tab>
            <Tab minWidth="100px">My Bets</Tab>
            <Tab minWidth="100px">Favourites</Tab>
            <Tab minWidth="100px">Starting Soon</Tab>
          </TabList>

          <TabPanels>
            <TabPanel padding={0}>
              {/* Lobby Section */}
              <Flex 
                alignItems="center"
                lineHeight="1.5"
                fontSize="1.125rem"
                fontWeight="bold">
                <MdOutlineSportsVolleyball />
                <Text ml={2}>Top Sports</Text>
              </Flex>
              <Slider {...carouselSettings}>
                {sportSliders.map(({
                  image,
                  alt,
                }, index) => (
                  <Box
                  key={index}
                  position="relative"
                  borderRadius="12px"
                  textAlign="center"
                  color="white"
                  p={4}
                  pl={0}
                  transition="all 0.2s ease-in-out"
                  _hover={{ transform: "translateY(-10px)" }}
                >
                  <Image
                    src={image}
                    style={{ width: "100%", height: "80%" }}
                    alt={alt}
                    borderRadius="md"
                  />
                </Box>
                ))}
              </Slider>
            </TabPanel>

            <TabPanel>
              <Flex alignItems="center" mb={6}>
                <FaMoneyBillWave />
                <Text ml={2}>My Bets</Text>
              </Flex>
            </TabPanel>

            <TabPanel>
              <Flex alignItems="center" mb={6}>
                <FaHeart />
                <Text ml={2}>Favourites</Text>
              </Flex>
            </TabPanel>

            <TabPanel>
              <Flex alignItems="center" mb={6}>
                <FaStopwatch />
                <Text ml={2}>Starting Soon</Text>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
}

export default Sports;
